import React from "react";
import {
  Box,
  Card,
  CardContent,
  CardMedia,
  Container,
  Stack,
  Typography,
} from "@mui/material";
import { HeadingTagComponent } from "../../../../components/atoms/Tags/HeadingTagComponent";
import { HeadingBlueComponent } from "../../../../components/atoms/Headings/HeadingBlueComponent";
import { HeadingRedComponent } from "../../../../components/atoms/Headings/HeadingRedComponent";
// import { Swiper, SwiperSlide } from "swiper/react";
// import { Autoplay, Pagination } from "swiper/modules";
// import "swiper/css";
// import "swiper/css/navigation";
// import "swiper/css/pagination";
import _ from "lodash";
import { LazyLoadImageComponent } from "../../../../components/atoms/LazyLoad/LazyLoadImageComponent";
import { UNI01, UNI02, UNI03, UNI04 } from "../../../../assets/Images";
import Marquee from "react-marquee-slider";
import styled from "styled-components";

export const UniListSection = () => {
  const members = [
    {
      image: UNI01,
    },
    {
      image: UNI02,
    },
    {
      image: UNI03,
    },
    {
      image: UNI04,
    },
  ];
  return (
    <Container maxWidth="xl">
      <Container maxWidth={"md"}>
        <Stack spacing={2}>
          <Stack spacing={2} sx={{ alignItems: "center" }}>
            <HeadingTagComponent text="Global Partnership" />
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "baseline",
                columnGap: 1,
              }}
            >
              <HeadingRedComponent variant="h4" text="Our Global " />
              <HeadingBlueComponent variant="h4" text="University Partners" />
            </Box>
          </Stack>
          <Typography
            variant="subtitle1"
            sx={{ fontFamily: "OakSans", textAlign: "center" }}
          >
            We proudly collaborate with top-tier universities across the globe
            to offer our students unparalleled access to world-class education.
          </Typography>
        </Stack>
      </Container>

      <div style={{ width: "100%", overflow: "hidden" }}>
        <Marquee velocity={50} minScale={0.7} resetAfterTries={200}>
          {_.map([...members, ...members], (member, index) => (
            <Box
              key={index + 1}
              sx={{
                padding: 2,
                textAlign: "center",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                width: 200, // Set a fixed width for each item for consistency
              }}
            >
              <LazyLoadImageComponent
                src={member.image}
                alt={`${index + 1}`}
                styles={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                }}
              />
            </Box>
          ))}
          {/* {images.map((src, index) => (
          <ImageWrapper key={index}>
            <img src={src} alt={`Slide ${index}`} style={{ width: '100%', height: 'auto' }} />
          </ImageWrapper>
        ))} */}
        </Marquee>
      </div>

      <div style={{ width: "100%", overflow: "hidden" }}>
        <Marquee velocity={50} minScale={0.7} direction={'ltr'} resetAfterTries={200}>
          {_.map([...members, ...members], (member, index) => (
            <Box
              key={index + 1}
              sx={{
                padding: 2,
                textAlign: "center",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                width: 200, // Set a fixed width for each item for consistency
              }}
            >
              <LazyLoadImageComponent
                src={member.image}
                alt={`${index + 1}`}
                styles={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                }}
              />
            </Box>
          ))}
        </Marquee>
      </div>
     
    </Container>
  );
};
