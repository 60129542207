import React from "react";

export const ForwardArrow = () => {
  return (
    <svg
      width="15"
      height="12"
      viewBox="0 0 15 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.81534 0.34375L8.63814 1.76175L11.3663 5.01675L0.015625 5.02975L0.0172935 7.02975L11.3371 7.01675L8.65483 10.2467L9.83703 11.6567L14.5458 5.98675L9.81534 0.34375Z"
        fill="#01203D"
      />
    </svg>
  );
};
