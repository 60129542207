import React from 'react'
import { LazyLoadImageComponent } from '../../components/atoms/LazyLoad/LazyLoadImageComponent'
import { STUDENT_SERVICES_MAIN } from '../../assets/Images'
import { Box, Stack } from '@mui/material'

export const StudyDestinations = () => {
  return (
    <Box sx={{mt:2, mb:4}}>
    <Stack spacing={4}>
      <Box component={"section"}>
        <LazyLoadImageComponent
          src={STUDENT_SERVICES_MAIN}
          alt={"main-img-student-service"}
          styles={{ width: "100%", height: "auto", objectFit: "cover" }}
        />
      </Box>

      {/* <Box component={'section'}>
      <PartnerWithUs/>
      </Box>

      <Box component={'section'}>
      <WhyChooseUs/>
      </Box>

      <Box component={"section"}>
        <ContactUsSection tagName="Reach out to us" />
      </Box>

      <Box component={"section"}>
       <GetInTouchSection/>
      </Box> */}
    </Stack>
  </Box>
  )
}
