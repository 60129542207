import _ from "lodash";

export const VALIDATE_EMAIL = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
export const TEXT_ONLY = /^[a-zA-Z\s]+$/;
export const CHECK_MESSAGE = /^\s*([a-zA-Z]+(\s+|$)){3,}/;
export const PHONE_NO = /^(?:\+?\d{1,3}[-.\s]?)?(?:\(?\d{3}\)?[-.\s]?)?\d{3}[-.\s]?\d{4}$/;

export const getText = (value) => {
  return _.get(value,'target.value');
};
