import * as React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Box, Container, Stack, Typography } from "@mui/material";
import { HeadingTagComponent } from "../../components/atoms/Tags/HeadingTagComponent";
import { HeadingRedComponent } from "../../components/atoms/Headings/HeadingRedComponent";
import { HeadingBlueComponent } from "../../components/atoms/Headings/HeadingBlueComponent";
import { LazyLoadImageComponent } from "../../components/atoms/LazyLoad/LazyLoadImageComponent";
import { STUDENT_SERVICES_MAIN } from "../../assets/Images";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";

export const Faqs = () => {
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <Box>
      <Box component={"section"}>
        <LazyLoadImageComponent
          src={STUDENT_SERVICES_MAIN}
          alt={"main-img-student-service"}
          styles={{ width: "100%", height: "auto", objectFit: "cover" }}
        />
      </Box>
      <Container maxWidth={"md"} sx={{ mb: 2, mt: 2 }}>
        <Stack spacing={8}>
          <Stack spacing={2}>
            <Stack spacing={2} sx={{ alignItems: "center" }}>
              <HeadingTagComponent text="Frequently Asked Questions" />
              <Stack sx={{ alignItems: "center" }}>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "baseline",
                    columnGap: 1,
                  }}
                >
                  <HeadingRedComponent variant="h4" text="Ask Us" />
                  <HeadingBlueComponent variant="h4" text="What you want to " />
                </Box>
                <HeadingBlueComponent variant="h4" text="What you want to " />
              </Stack>
            </Stack>
            <Typography
              variant="subtitle1"
              sx={{ fontFamily: "OakSans", textAlign: "center" }}
            >
              OASES is here to answer all your questions about studying abroad
              and our consultancy services. If you have any questions that
              aren’t covered in the FAQs, feel free to email us directly.
            </Typography>
          </Stack>
          <Box>
            <Accordion
              expanded={expanded === "panel1"}
              onChange={handleChange("panel1")}
            >
              <AccordionSummary
                expandIcon={
                  expanded === "panel1" ? <RemoveIcon /> : <AddIcon />
                }
                aria-controls="panel1bh-content"
                id="panel1bh-header"
              >
                <Typography
                  variant="subtitle1"
                  sx={{ width: "33%", flexShrink: 0 }}
                >
                  General settings
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography variant="subtitle2">
                  Nulla facilisi. Phasellus sollicitudin nulla et quam mattis
                  feugiat. Aliquam eget maximus est, id dignissim quam.
                </Typography>
              </AccordionDetails>
            </Accordion>

            {/* <Accordion
            expanded={expanded === "panel2"}
            onChange={handleChange("panel2")}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel2bh-content"
              id="panel2bh-header"
            >
              <Typography sx={{ width: "33%", flexShrink: 0 }}>
                Users
              </Typography>
              <Typography sx={{ color: "text.secondary" }}>
                You are currently not an owner
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                Donec placerat, lectus sed mattis semper, neque lectus feugiat
                lectus, varius pulvinar diam eros in elit. Pellentesque
                convallis laoreet laoreet.
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion
            expanded={expanded === "panel3"}
            onChange={handleChange("panel3")}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel3bh-content"
              id="panel3bh-header"
            >
              <Typography sx={{ width: "33%", flexShrink: 0 }}>
                Advanced settings
              </Typography>
              <Typography sx={{ color: "text.secondary" }}>
                Filtering has been entirely disabled for whole web server
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                Nunc vitae orci ultricies, auctor nunc in, volutpat nisl.
                Integer sit amet egestas eros, vitae egestas augue. Duis vel est
                augue.
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion
            expanded={expanded === "panel4"}
            onChange={handleChange("panel4")}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel4bh-content"
              id="panel4bh-header"
            >
              <Typography sx={{ width: "33%", flexShrink: 0 }}>
                Personal data
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                Nunc vitae orci ultricies, auctor nunc in, volutpat nisl.
                Integer sit amet egestas eros, vitae egestas augue. Duis vel est
                augue.
              </Typography>
            </AccordionDetails>
          </Accordion> */}
          </Box>
        </Stack>
      </Container>
    </Box>
  );
};
