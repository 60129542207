import { Box, Container, Grid, Stack } from "@mui/material";
import React from "react";
import { LazyLoadImageComponent } from "../../components/atoms/LazyLoad/LazyLoadImageComponent";
import { ABOUT_US_MAIN } from "../../assets/Images";
import { ContactUsSection } from "../StudentServices/Sections/ContactUsSection/ContactUsSection";
import { HeadingBlueComponent } from "../../components/atoms/Headings/HeadingBlueComponent";
import { HeadingRedComponent } from "../../components/atoms/Headings/HeadingRedComponent";
import { GetInTouchSection } from "./GetInTouchSection/GetInTouchSection";
import { NewsUpdateSection, ProfessionalDevelopment } from "../../components/organisms";

export const ContactUs = () => {
  return (
    <Box>
      <Stack spacing={10} mb={5}>
        <Box component={"section"}>
          <LazyLoadImageComponent
            src={ABOUT_US_MAIN}
            alt={"main-img-about-us"}
            styles={{ width: "100%", height: "auto", objectFit: "cover" }}
          />
        </Box>

        <Box component={"section"}>
          <GetInTouchSection />
        </Box>

        <Box component={"section"}>
          <ContactUsSection tagName="Reach out to us" />
        </Box>

        <Box component={"section"}>
      <ProfessionalDevelopment />
    </Box>
      </Stack>
    </Box>
  );
};
