import {
  Box,
  Container,
  Divider,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import React from "react";
import { HeadingBlueComponent } from "../../../../components/atoms/Headings/HeadingBlueComponent";
import { HeadingRedComponent } from "../../../../components/atoms/Headings/HeadingRedComponent";
import { PlusCountComponent } from "../../../../components/atoms/PlusCount/PlusCountComponent";
import { HeadingTagComponent } from "../../../../components/atoms/Tags/HeadingTagComponent";
import Grid2 from "@mui/material/Unstable_Grid2";
import { ForwardArrow } from "../../../../components/atoms/SvgIcons/ForwardArrow";
import { LazyLoadImageComponent } from "../../../../components/atoms/LazyLoad/LazyLoadImageComponent";
import { GIRL_WITH_BOOKS } from "../../../../assets/Images";

export const AboutUsSection = () => {
  return (
    <>
      <Container maxWidth="lg">
        <Stack spacing={5}>
          <Box>
            <Stack spacing={2}>
              <Stack spacing={2} alignItems={"center"}>
                <HeadingTagComponent text="About Us" />
                <Grid
                  container
                  sx={{
                    alignItems: "baseline",
                    gap: 1,
                    justifyContent: "center",
                  }}
                >
                  <Grid item>
                    <HeadingBlueComponent variant="h4" text="Why Choose" />
                  </Grid>
                  <Grid item>
                    <HeadingRedComponent variant="h4" text="OASES" />
                  </Grid>
                </Grid>
              </Stack>
              <Stack spacing={2}>
                <Typography
                  variant="subtitle1"
                  sx={{ fontFamily: "OakSans", textAlign: "center" }}
                >
                  OASES Education (Pvt) Ltd, established in 2010 in Kurunegala,
                  Sri Lanka, is a leading education consultancy focused on
                  supporting students, learning professionals, and educational
                  institutions. It aims to simplify studying and interning
                  abroad through technology and knowledge consulting. OASES
                  helps students select suitable courses and universities based
                  on market trends, financial budgets, and other factors.
                </Typography>

                <Typography
                  variant="subtitle1"
                  sx={{ fontFamily: "OakSans", textAlign: "center" }}
                >
                  Our comprehensive services include application assistance,
                  interview preparation, visa processing, and accommodation
                  arrangements. OASES represents numerous universities
                  worldwide, including in the USA, Canada, UK, Australia, and
                  several Asian countries, guiding students from bachelor's
                  degree programs to Ph.D. and pathway programs. Our counselors
                  are trained to provide accurate, up-to-date information to
                  students and parents, ensuring a successful education path
                  abroad.
                </Typography>

                <Typography
                  variant="subtitle1"
                  sx={{ fontFamily: "OakSans", textAlign: "center" }}
                >
                  OASES Educational Services (Pvt) Ltd is delighted to announce
                  its global expansion by establishing branch offices worldwide.
                  These branches enable us to reach a broader audience and offer
                  innovative learning options, ensuring that exceptional
                  education is accessible to students around the globe.
                </Typography>
              </Stack>
            </Stack>
          </Box>

          <Grid container sx={{alignItems:'center',columnGap:2, justifyContent:'center'}}>
            <Grid item xl={5} lg={5} md={5} sm={10} xs={12}>
              <Stack spacing={6}>
                <Box position="relative" display="inline-block" mb={2}>
                  <Typography
                    variant="caption" // Adjust the variant to control font size
                    color="text.primary" // Adjust color as needed
                    fontWeight={600} // Adjust weight as needed
                    sx={{
                      fontFamily: "Arial, sans-serif", // Set desired font family
                    }}
                  >
                    Connecting Students with Global Excellence.
                  </Typography>
                  <Box
                    sx={{
                      position: "absolute",
                      bottom: "-2px", // Adjust vertical position
                      left: 0,
                      width: "30px", // Adjust the width of the underline
                      height: "3px", // Adjust the thickness of the underline
                      bgcolor: "#e94e1b", // Set the underline color
                    }}
                  />
                </Box>

                <Typography
                  variant="subtitle1" // Adjust the variant to control font size
                  color="text.primary" // Adjust color as needed
                  fontWeight={500} // Adjust weight as needed
                  sx={{
                    fontFamily: "Arial, sans-serif", // Set desired font family
                  }}
                >
                  Empowering students for over a decade, OASES Educational
                  Services Pvt Ltd has successfully guided numerous students to
                  study abroad, partnering with leading universities worldwide.
                </Typography>

                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <PlusCountComponent count={1800} text="Students Abroad" />
                  <PlusCountComponent count={15} text="Years in Business" />
                  <PlusCountComponent count={150} text="Partners" />
                </Box>

                <Box
                  position="relative"
                  display="inline-block"
                  mb={2}
                  sx={{ cursor: "pointer" }}
                >
                  <Box sx={{mb:1}}>
                    <Divider />
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      paddingInline: 3,
                    }}
                  >
                    <Typography
                      variant="caption" // Adjust the variant to control font size
                      color="text.primary" // Adjust color as needed
                      fontWeight={600} // Adjust weight as needed
                      sx={{
                        fontFamily: "Arial, sans-serif", // Set desired font family
                      }}
                    >
                      Find out more about OASES
                    </Typography>

                    <ForwardArrow />
                  </Box>
                  <Box
                    sx={{
                      position: "absolute",
                      bottom: -10, // Adjust vertical position
                      left: 0,
                      width: "100%", // Adjust the width of the underline
                      height: "3px", // Adjust the thickness of the underline
                      bgcolor: "#e94e1b", // Set the underline color
                    }}
                  />
                </Box>
              </Stack>
            </Grid>
            <Grid item xl={5} lg={5} md={5} sx={{display:{xl:'block', lg:'block', md:'block', sm:'none', xs:'none'}}}>
              <LazyLoadImageComponent src={GIRL_WITH_BOOKS} alt={'img'} styles={{width:350}}/>
            </Grid>
          </Grid>
        </Stack>
      </Container>
      {/* <Box>
        <Container maxWidth={"xl"}  sx={{ bgcolor: "#DCE9F1", mt: 2, pt: 3, pb: 3 }}>
          <Grid
            container
            sx={{
              paddingInline: 2,
              justifyContent: "space-between",
              rowGap: 2,
            }}
          >
            <Grid item xl={5} lg={5} md={6} sm={12} xs={12}>
              <Typography variant="subtitle1" sx={{ fontFamily: "OakSans" }}>
                Empowering students for over a decade, OASES Educational
                Services Pvt Ltd has successfully guided numerous students to
                study abroad, partnering with leading universities worldwide.
              </Typography>
            </Grid>
            <Grid
              item
              xl={5}
              lg={5}
              md={5}
              sm={12}
              xs={12}
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <PlusCountComponent count={1800} text="Students Abroad" />
              <PlusCountComponent count={15} text="Years in Business" />
              <PlusCountComponent count={150} text="Partners" />
            </Grid>
          </Grid>
        </Container>
      </Box> */}
    </>
  );
};
