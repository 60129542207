import { Box, Stack } from "@mui/material";
import React from "react";
import { STUDENT_SERVICES_MAIN } from "../../assets/Images";
import { LazyLoadImageComponent } from "../../components/atoms/LazyLoad/LazyLoadImageComponent";
import { GetInTouchSection } from "../ContactUs/GetInTouchSection/GetInTouchSection";
import { ContactUsSection } from "../StudentServices/Sections/ContactUsSection/ContactUsSection";
import { PartnerWithUs } from "./PartnerWithUs/PartnerWithUs";
import { WhyChooseUs } from "./WhyChooseUs/WhyChooseUs";

export const BecomeAnAgent = () => {
  return (
    <Box>
      <Stack spacing={4} mb={5}>
        <Box component={"section"}>
          <LazyLoadImageComponent
            src={STUDENT_SERVICES_MAIN}
            alt={"main-img-student-service"}
            styles={{ width: "100%", height: "auto", objectFit: "cover" }}
          />
        </Box>

        <Box component={'section'}>
        <PartnerWithUs/>
        </Box>

        <Box component={'section'}>
        <WhyChooseUs/>
        </Box>

        <Box component={"section"}>
          <ContactUsSection tagName="Reach out to us" />
        </Box>

        <Box component={"section"}>
         <GetInTouchSection/>
        </Box>
      </Stack>
    </Box>
  );
};
