import React from "react";
import { Avatar, Box, Container, Grid, Stack, Typography } from "@mui/material";
import { HeadingTagComponent } from "../../../components/atoms/Tags/HeadingTagComponent";
import { HeadingBlueComponent } from "../../../components/atoms/Headings/HeadingBlueComponent";
import { HeadingRedComponent } from "../../../components/atoms/Headings/HeadingRedComponent";
import { BECOME_AGENT_DOLLER_SIGN, BECOME_AGENT_GLOBE, BECOME_AGENT_HAND } from "../../../assets/Images";

export const WhyChooseUs = () => {
  return (
    <Container maxWidth={"xl"} className="global-standards-container">
      <Container maxWidth={"lg"}>
        <Stack>
          <Container maxWidth={"lg"}>
            <Stack sx={{ alignItems: "center" }} spacing={2}>
              <HeadingTagComponent colorMode="dark" text="Why Choose Us" />
              <Box>
                <Grid
                  container
                  sx={{
                    justifyContent: "center",
                    alignItems: "baseline",
                    columnGap: 1,
                  }}
                >
                  <Grid item>
                    <HeadingRedComponent variant="h4" text="Why" />
                  </Grid>
                  <Grid item>
                    <HeadingBlueComponent
                      colorMode="dark"
                      variant="h4"
                      text="Partner"
                    />
                  </Grid>
                </Grid>
                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <HeadingBlueComponent
                    colorMode="dark"
                    variant="h4"
                    text="With Us?"
                  />
                </Box>
              </Box>

              <Box>
                <Typography
                  variant="subtitle1"
                  sx={{
                    fontFamily: "OakSans",
                    color: "#ffffff",
                    textAlign: "center",
                  }}
                >
                  Partnering with OASES provides you with unparalleled
                  opportunities to grow your business while making a difference
                  in students' lives. As an agent, you’ll gain access to a
                  global network of prestigious universities, receive
                  competitive commissions and incentives, and benefit from our
                  dedicated support and resources.
                </Typography>
              </Box>
            </Stack>
          </Container>
          <Grid container sx={{justifyContent:'space-between'}}>
            <Grid item xl={3} lg={3} md={3} sm={12} xs={12}>
              <Stack>
               <Box>
               <Avatar src={BECOME_AGENT_GLOBE} alt="gloabe" sx={{borderRadius:0}} />
                <Typography
                  variant="subtitle1"
                  sx={{
                    fontFamily: "OakSans",
                    color: "#ffffff",
                    textAlign: "left",
                    lineHeight: "32px",
                    fontWeight: 500,
                  }}
                >
                  Access to a Global Network
                </Typography>
               </Box>

                <Typography
                  variant="subtitle1"
                  sx={{
                    fontFamily: "OakSans",
                    color: "#ffffff",
                    textAlign: "left",
                    lineHeight: "32px",
                    fontWeight: 300,
                  }}
                >
                  Connect with leading universities across the globe, offering
                  diverse educational opportunities for students.
                </Typography>
              </Stack>
            </Grid>
            <Grid item xl={3} lg={3} md={3} sm={12} xs={12}>
              <Stack>
              <Box>
              <Avatar src={BECOME_AGENT_DOLLER_SIGN} alt="doller_sign" sx={{borderRadius:0}} />
                <Typography
                  variant="subtitle1"
                  sx={{
                    fontFamily: "OakSans",
                    color: "#ffffff",
                    textAlign: "left",
                    lineHeight: "32px",
                    fontWeight: 500,
                  }}
                >
                  Competitive Commissions
                </Typography>
              </Box>
              

                <Typography
                  variant="subtitle1"
                  sx={{
                    fontFamily: "OakSans",
                    color: "#ffffff",
                    textAlign: "left",
                    lineHeight: "32px",
                    fontWeight: 300,
                  }}
                >
                  Earn attractive commissions and incentives for your efforts in
                  placing students at top institutions.
                </Typography>
              </Stack>
            </Grid>
            <Grid item xl={3} lg={3} md={3} sm={12} xs={12}>
              <Stack>
             <Box>
             <Avatar src={BECOME_AGENT_HAND} alt="hand" sx={{borderRadius:0}} />
                <Typography
                  variant="subtitle1"
                  sx={{
                    fontFamily: "OakSans",
                    color: "#ffffff",
                    textAlign: "left",
                    lineHeight: "32px",
                    fontWeight: 500,
                  }}
                >
                  Dedicated Support
                </Typography>
             </Box>

                <Typography
                  variant="subtitle1"
                  sx={{
                    fontFamily: "OakSans",
                    color: "#ffffff",
                    textAlign: "left",
                    lineHeight: "32px",
                    fontWeight: 300,
                  }}
                >
                  Receive comprehensive support and resources, ensuring your
                  success as an OASES agent.
                </Typography>
              </Stack>
            </Grid>
          </Grid>
        </Stack>
      </Container>
    </Container>
  );
};
