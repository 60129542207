import { Box, Stack } from "@mui/material";
import React from "react";
import {  MAIN_SLIDER_02, MAIN_SLIDER_03, MAIN_SLIDER_IMG_01 } from "../../assets/Images";
import { HeroSliderComponent } from "../../components/atoms/Sliders/HeroSliderComponent/HeroSliderComponent";
import {
  NewsUpdateSection,
  ProfessionalDevelopment,
  StudyDestinations,
} from "../../components/organisms";
import { StudentStories } from "../../components/organisms/Sections/StudentStories/StudentStories";
import { AboutUsSection } from "./Sections/AboutUsSection/AboutUsSection";
import { DreamAbroadSection } from "./Sections/DreamAbroadSection/DreamAbroadSection";
import { GlobalStandardSection } from "./Sections/GlobalStandardsSection/GlobalStandardSection";
import { InternationalOffices } from "./Sections/InternationalOffices/InternationalOffices";
import { UniListSection } from "./Sections/UniListSection/UniListSection";

export const HomePage = () => {
  const images = [
    {
      image: MAIN_SLIDER_IMG_01,
    },
    {
      image: MAIN_SLIDER_02,
    },
    {
      image: MAIN_SLIDER_03,
    },
  ];

  return (
    <Stack spacing={8} sx={{ pb: 10 }}>
    <Box component={"section"}>
      <HeroSliderComponent imageList={images} />
    </Box>
    {/* About us  */}
    <Box component={"section"}>
      <AboutUsSection />
    </Box>

    {/*  */}
    <Box component={"section"}>
      <UniListSection/>
    </Box>
   
    {/* International Office */}
    <Box component={"section"}>
      <InternationalOffices />
    </Box>

    {/* Study Destinations */}
    <Box component={"section"}>
      <StudyDestinations />
    </Box>

    {/* Global Standards */}
    <Box component={"section"}>
      <GlobalStandardSection />
    </Box>

    {/* Dream abroad */}
    <Box component={"section"}>
      <DreamAbroadSection />
    </Box>

      {/* Student Stories */}
      <Box component={"section"}>
      <StudentStories />
    </Box>

    {/* Professional Development */}
    <Box component={"section"}>
      <ProfessionalDevelopment />
    </Box>

   

    {/* Latest News */}
    <Box component={"section"}>
      <NewsUpdateSection />
    </Box>
  </Stack>
  );
};
