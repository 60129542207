import React from "react";
import { Route, Routes } from "react-router-dom";
import { WebsiteLayout } from "../layouts";
import {
  AboutUsPage,
  BecomeAnAgent,
  ContactUs,
  Faqs,
  Gallery,
  HomePage,
  NewsBlogs,
  PageNotFound,
  StudentServices,
  StudyDestinations,
} from "../modules";
import { ScrollToTop } from "../core/utils/ScrollToTop";

export const ApplicationRoutes = () => {
  return (
   <>
   <ScrollToTop/>
     <Routes>
      <Route path="/">
        <Route element={<WebsiteLayout />}>
          <Route index element={<HomePage />} />
          <Route index path="/Home" element={<HomePage />} />
          <Route path="/about-us" element={<AboutUsPage />} />
          <Route path="/student-services" element={<StudentServices />} />
          <Route path="/contact-us" element={<ContactUs />} />
          <Route path="/news-blogs" element={<NewsBlogs />} />
          <Route path="/faqs" element={<Faqs />} />
          <Route path="/become-an-agent" element={<BecomeAnAgent />} />
          <Route path="/study-destinations" element={<StudyDestinations />} />
          <Route path="/photo-gallery" element={<Gallery />} />
          <Route path="*" element={<PageNotFound />} />
        </Route>
      </Route>
    </Routes>
   </>
  );
};
