import React, { useEffect, useState } from "react";
import ArrowOutwardIcon from "@mui/icons-material/ArrowOutward";
import CloseIcon from "@mui/icons-material/Close";
import MenuIcon from "@mui/icons-material/Menu";
import { Divider, Stack } from "@mui/material";
import AppBar from "@mui/material/AppBar";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import _ from "lodash";
import { Link, useNavigate } from "react-router-dom";
import { LOGO_WITH_TEXT, LOGO_WITH_TEXT_BLACK } from "../../../assets/Images";
import { TextButtonComponent } from "../../atoms/Buttons/TextButtonComponent";
import { NavbarStyles } from "./NavbarStyle";
import { navigationDetails } from "./Navigation";
import { DropdownNavComponent } from "../../atoms/Inputs/DropdownNavComponent";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { LazyLoadImageComponent } from "../../atoms/LazyLoad/LazyLoadImageComponent";

export const NavbarComponent = ({ isSticky = false }) => {
  const [openDrawer, setOpenDrawer] = useState(false);
  const navigate = useNavigate();
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const handleClickMobile = (path) => {
    setOpenDrawer(false);
    navigate(path);
  };

  const toolbarStyles = isSticky
    ? { ...NavbarStyles.toolbarDashboardScrolled }
    : { ...NavbarStyles.toolbarDashboardDefault };

  return (
    <>
      <AppBar
        position="sticky"
        sx={{
          backgroundColor: isSticky ? "#ffffff" : "transparent",
          boxShadow: isSticky ? "drop" : "none",
          transition: "all 0.3s ease-in-out",
          top: 0,
        }}
      >
        <Container maxWidth={"xl"}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              gap: 2,
              paddingInlineEnd: 5,
              mt:1,
              mb:1
            }}
          >
            <Typography
              variant="subtitle2"
              fontFamily={"OakSans"}
              color={isSticky ? "#052343" : "#fff"}
              fontWeight={500}
              sx={{
                cursor: "pointer",
                lineHeight: 2.5,
                textDecoration: "none",

                textUnderlineOffset: 5,
                fontWeight: 500,
              }}
              onClick={() => navigate("/news-blogs")}
              onMouseEnter={(e) => {
                e.target.style.textDecoration = "underline";
                e.target.style.textDecorationColor = "#FD5B2C";
                e.target.style.textDecorationStyle = "solid";
              }}
              onMouseLeave={(e) => (e.target.style.textDecoration = "none")}
            >
              News and Blogs
            </Typography>
            <Divider orientation="vertical" sx={{bgcolor:'#fff'}} flexItem/>
            <Typography
              variant="subtitle2"
              fontFamily={"OakSans"}
              color={isSticky ? "#052343" : "#fff"}
              fontWeight={500}
              sx={{
                cursor: "pointer",
                lineHeight: 2.5,
                textDecoration: "none",

                textUnderlineOffset: 5,
                fontWeight: 500,
              }}
              onMouseEnter={(e) => {
                e.target.style.textDecoration = "underline";
                e.target.style.textDecorationColor = "#FD5B2C";
                e.target.style.textDecorationStyle = "solid";
              }}
              onMouseLeave={(e) => (e.target.style.textDecoration = "none")}
              onClick={() => navigate("/photo-gallery")}
            >
              Gallery
            </Typography>
            <Divider orientation="vertical" sx={{bgcolor:'#fff'}} flexItem/>
            <Typography
              variant="subtitle2"
              fontFamily={"OakSans"}
              color={isSticky ? "#052343" : "#fff"}
              fontWeight={500}
              sx={{
                cursor: "pointer",
                lineHeight: 2.5,
                textDecoration: "none",

                textUnderlineOffset: 5,
                fontWeight: 500,
              }}
              onMouseEnter={(e) => {
                e.target.style.textDecoration = "underline";
                e.target.style.textDecorationColor = "#FD5B2C";
                e.target.style.textDecorationStyle = "solid";
              }}
              onMouseLeave={(e) => (e.target.style.textDecoration = "none")}
              onClick={() => navigate("/faqs")}
            >
              FAQs
            </Typography>
          </Box>
        </Container>
        {isSticky ? <Divider /> : null}

        <Container maxWidth="xl">
          <Toolbar sx={toolbarStyles}>
            <Box
              sx={{
                flexGrow: 1,
                display: { xs: "flex", md: "none" },
                justifyContent: "space-between",
                alignItems: "center",
                "&:hover": {
                  color: isSticky ? "#052343" : "#ffffff",
                  borderColor: isSticky ? "#052343" : "#ffffff",
                  backgroundColor: "#ffffff20",
                },
              }}
             
            >
              <Avatar
                src={isSticky ? LOGO_WITH_TEXT_BLACK : LOGO_WITH_TEXT}
                alt="Logo"
                sx={{ borderRadius: 0, width: 160 }}
                onClick={()=>navigate("#")}
              />
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={() => setOpenDrawer(true)}
                color={!isSticky ? "inherit" : "#3D3D3D"}
              >
                <MenuIcon />
              </IconButton>
            </Box>

            <Avatar
              src={isSticky ? LOGO_WITH_TEXT_BLACK : LOGO_WITH_TEXT}
              alt="Logo"
              sx={{
                borderRadius: 0,
                width: 160,
                cursor:'pointer',
                // borderRadius: "10px", // Optional: Adds rounded corners to the image
                // boxShadow: "0 0 15px 5px rgba(0, 255, 0, 0.7)", // Glowing effect
                // transition: "box-shadow 0.3s ease", // Smooth transition
                // "&:hover": {
                //   boxShadow: "0 0 25px 10px rgba(0, 255, 0, 1)", // Stronger glow on hover
                // },
                display: {
                  xl: "block",
                  lg: "block",
                  md: "block",
                  sm: "none",
                  xs: "none",
                },
              }}
              onClick={()=>navigate("/")}
            />

            <Box
              sx={{
                flexGrow: 1,
                display: { xs: "none", md: "flex" },
                paddingInlineStart: { xl: 5, lg: 5, md: 1 },
                gap: { xl: 2, lg: 2, md: 1 },
              }}
            >
              {_.map(navigationDetails, (option, index) => {
                return (
                  <React.Fragment key={index + 1}>
                    {_.get(option, "more", "") ? (
                      <>
                        <DropdownNavComponent
                          menuName={_.get(option, "more.dropdown", "")}
                          list={_.get(option, "more.list", [])}
                          isSticky={isSticky}
                        />
                      </>
                    ) : (
                      <Link
                        to={_.get(option, "path", "")}
                        style={{
                          fontSize: 16,
                          fontFamily: "OakSans",
                          color: isSticky ? "#052343" : "#ffffff",
                          textDecoration: "none",

                          textUnderlineOffset: 5,
                          fontWeight: 500,
                        }}
                        onMouseEnter={(e) => {
                          e.target.style.textDecoration = "underline";
                          e.target.style.textDecorationColor = "#FD5B2C";
                          e.target.style.textDecorationStyle = "solid";
                        }}
                        onMouseLeave={(e) =>
                          (e.target.style.textDecoration = "none")
                        }
                      >
                        {_.get(option, "page", "")}
                      </Link>
                    )}
                  </React.Fragment>
                );
              })}
              {/* {pages.map((page) => (
                <Button
                  key={page}
                  onClick={handleCloseNavMenu}
                  sx={{
                    my: 2,
                    color: !isSticky ? "white" : "#052343",
                    display: "block",
                    textTransform: "none",
                    fontFamily: "OakSans",
                  }}
                >
                  {page}
                </Button>
              ))} */}
            </Box>

            <Box
              sx={{
                display: {
                  xl: "flex",
                  lg: "flex",
                  md: "flex",
                  sm: "none",
                  xs: "none",
                },
                columnGap: 1,
                flexGrow: 0,
              }}
            >
              <TextButtonComponent
                text="Become an Agent"
                variant="outlined"
                sx={{
                  textTransform: "initial",
                  borderRadius: 10,
                  borderColor: isSticky ? "#052343" : "#ffffff",
                  color: isSticky ? "#052343" : "#ffffff",
                  fontFamily: "OakSans",
                  "&:hover": {
                    color: isSticky ? "#052343" : "#ffffff",
                    borderColor: isSticky ? "#052343" : "#ffffff",
                    backgroundColor: "#ffffff20",
                  },
                }}
                onClick={() => navigate("/become-an-agent")}
              />
              <TextButtonComponent
                text="Contact Us"
                variant="contained"
                endIcon={<ArrowOutwardIcon />}
                sx={{
                  textTransform: "initial",
                  borderRadius: 10,
                  borderColor: isSticky ? "#ffffff" : "#3D3D3D",
                  backgroundColor: isSticky ? "#052343" : "#ffffff",
                  color: isSticky ? "#ffffff" : "#3D3D3D",
                  fontFamily: "OakSans",
                  "&:hover": {
                    color: "#ffffff",
                  },
                }}
                onClick={() => navigate("/contact-us")}
              />
            </Box>
          </Toolbar>
        </Container>
      </AppBar>

      <SwipeableDrawer
        anchor={"right"}
        open={openDrawer}
        onClose={() => setOpenDrawer(false)}
        onOpen={() => setOpenDrawer(true)}
      >
        <Box p={1} sx={{ display: "flex", justifyContent: "flex-end" }}>
          <IconButton
            sx={{ color: "#FD5B2C" }}
            onClick={() => setOpenDrawer(false)}
          >
            <CloseIcon />
          </IconButton>
        </Box>

        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            pl: 2,
            pr: 2,
            pb: 2,
          }}
        >
          <Avatar
            src={LOGO_WITH_TEXT_BLACK}
            alt="logo"
            sx={{ width: "100%", borderRadius: 0, height: "auto" }}
          />
        </Box>
        <Divider sx={{ bgcolor: "#05234320" }} />

        <List sx={{ width: 250 }}>
          {_.map(navigationDetails, (details, index) => {
            return (
              <React.Fragment key={index + 1}>
                {_.get(details, "more", "") ? (
                  <div>
                    <Accordion
                      sx={{ boxShadow: "none" }}
                      expanded={expanded === `panel${index + 1}`}
                      onChange={handleChange(`panel${index + 1}`)}
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls={`panel${index + 1}bh-content`}
                        id={`panel${index + 1}bh-header`}
                      >
                        <Typography
                          variant="subtitle1"
                          sx={{
                            paddingInlineStart: 1,
                            fontFamily: "OakSans",
                            fontWeight: 600,
                            color: "#052343",
                          }}
                        >
                          {_.get(details, "more.dropdown", "")}
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        {_.map(
                          _.get(details, "more.list", []),
                          (listItem, index) => {
                            return (
                              <ListItem disablePadding key={index + 1}>
                                <ListItemButton>
                                  <Typography
                                    variant="subtitle1"
                                    sx={{
                                      fontFamily: "OakSans",
                                      color: "#052343",
                                      fontWeight: 600,
                                    }}
                                    onClick={() =>
                                      handleClickMobile(
                                        _.get(listItem, "path", "")
                                      )
                                    }
                                  >
                                    {_.get(listItem, "page", "")}
                                  </Typography>
                                </ListItemButton>
                              </ListItem>
                            );
                          }
                        )}
                      </AccordionDetails>
                    </Accordion>
                  </div>
                ) : (
                  <ListItem disablePadding>
                    <ListItemButton>
                      <Typography
                        variant="subtitle1"
                        sx={{
                          fontFamily: "OakSans",
                          color: "#052343",
                          fontWeight: 600,
                        }}
                        onClick={() =>
                          handleClickMobile(_.get(details, "path", ""))
                        }
                      >
                        {_.get(details, "page", "")}
                      </Typography>
                    </ListItemButton>
                  </ListItem>
                )}
              </React.Fragment>
            );
          })}
        </List>

        <Stack sx={{ p: 2 }} spacing={1}>
          <TextButtonComponent
            text="Become an Agent"
            variant="outlined"
            sx={{
              textTransform: "initial",
              borderRadius: 10,
              borderColor: "#052343",
              color: "#052343",
              fontFamily: "OakSans",
            }}
            onClick={() => navigate("/become-an-agent")}
          />
          <TextButtonComponent
            text="Contact Us"
            variant="contained"
            endIcon={<ArrowOutwardIcon />}
            sx={{
              textTransform: "initial",
              borderRadius: 10,
              borderColor: "#3D3D3D",
              backgroundColor: "#052343",
              color: "#ffffff",
              fontFamily: "OakSans",
            }}
            onClick={() => navigate("/contact-us")}
          />
        </Stack>
      </SwipeableDrawer>
    </>
  );
};
