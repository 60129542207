import React from 'react'
import { LazyLoadImageComponent } from '../../components/atoms/LazyLoad/LazyLoadImageComponent'
import { Box, Container, Stack, Typography } from '@mui/material'
import { HeadingTagComponent } from '../../components/atoms/Tags/HeadingTagComponent'
import { HeadingRedComponent } from '../../components/atoms/Headings/HeadingRedComponent'
import { HeadingBlueComponent } from '../../components/atoms/Headings/HeadingBlueComponent'
import { STUDENT_SERVICES_MAIN } from '../../assets/Images'

export const Gallery = () => {
  return (
    <Box>
    <Box component={"section"}>
      <LazyLoadImageComponent
        src={STUDENT_SERVICES_MAIN}
        alt={"main-img-student-service"}
        styles={{ width: "100%", height: "auto", objectFit: "cover" }}
      />
    </Box>
    <Container maxWidth={"md"} sx={{ mb: 2, mt: 2 }}>
      <Stack spacing={8}>
        <Stack spacing={2}>
          <Stack spacing={2} sx={{ alignItems: "center" }}>
            <HeadingTagComponent text="Explore Our Events" />
            <Stack sx={{ alignItems: "center" }}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "baseline",
                  columnGap: 1,
                }}
              >
                
                <HeadingBlueComponent variant="h4" text="Our" />
                <HeadingRedComponent variant="h4" text="Events" />
              </Box>
            </Stack>
          </Stack>

          


          {/* <Typography
            variant="subtitle1"
            sx={{ fontFamily: "OakSans", textAlign: "center" }}
          >
            OASES is here to answer all your questions about studying abroad
            and our consultancy services. If you have any questions that
            aren’t covered in the FAQs, feel free to email us directly.
          </Typography> */}
        </Stack>
       
      </Stack>
    </Container>
  </Box>
  )
}
