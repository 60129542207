import React from "react";
import { Box, Button, Container, Grid, Typography } from "@mui/material";
import { HeadingBlueComponent } from "../../../atoms/Headings/HeadingBlueComponent";
import { HeadingRedComponent } from "../../../atoms/Headings/HeadingRedComponent";
import ArrowOutwardIcon from "@mui/icons-material/ArrowOutward";
import { HeadingTagComponent } from "../../../atoms/Tags/HeadingTagComponent";
import { TextButtonComponent } from "../../../atoms/Buttons/TextButtonComponent";

export const ProfessionalDevelopment = () => {
  return (
    <Container maxWidth={"xl"} sx={{ bgcolor: "#01203D", pt: 10, pb: 10 }}>
      <Container maxWidth={"sm"}>
        <Grid
          container
          sx={{ flexDirection: "column", alignItems: "center", gap: 2 }}
        >
          <Grid item>
            <HeadingTagComponent
              text="Professional Development"
              colorMode="dark"
            />
          </Grid>
          <Grid item>
            <Grid
              container
              sx={{ alignItems: "baseline", justifyContent: "center", gap: 1 }}
            >
              <Grid item>
                <HeadingBlueComponent
                  variant="h4"
                  colorMode="dark"
                  text="Ready To Join"
                />
              </Grid>
              <Grid item>
                <HeadingRedComponent variant="h4" text="Our Team ?" />
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Typography
              variant="subtitle1"
              sx={{
                fontFamily: "OakSans",
                color: "#ffffff",
                textAlign: "center",
              }}
            >
              Join our network of trusted agents and help students achieve their
              educational dreams. We offer comprehensive support and resources
              to help you succeed.
            </Typography>
          </Grid>
          <Grid item>
            <Button variant="outlined" sx={{color :'#fff', borderColor:'#fff', borderRadius:5,textTransform:'capitalize', fontFamily: "OakSans" ,":hover":{
              backgroundColor:'#fff',
              color:'initial'
            }}}>Apply Now</Button>
          </Grid>
        </Grid>
      </Container>
    </Container>
  );
};
