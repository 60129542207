import React from "react";
import { Box, Container, Stack, Typography } from "@mui/material";
import { HeadingTagComponent } from "../../../components/atoms/Tags/HeadingTagComponent";
import { HeadingBlueComponent } from "../../../components/atoms/Headings/HeadingBlueComponent";
import { HeadingRedComponent } from "../../../components/atoms/Headings/HeadingRedComponent";


export const PartnerWithUs = () => {
  return (
    <Container maxWidth={"lg"}>
      <Stack spacing={2} alignItems={"center"} sx={{ paddingInline: 2 }}>
        <HeadingTagComponent text="Partner With Us" />
        <Stack alignItems={"center"}>
          <HeadingBlueComponent variant="h4" text="Join Out Network of " />
          <HeadingRedComponent variant="h4" text="Educational Agents" />
        </Stack>

        <Box sx={{ width: "100%" }}>
          <Typography variant="subtitle1" sx={{ fontFamily: "OakSans", textAlign:'center'}}>
           
          Become an integral part of OASES by joining our network of dedicated educational agents. Together, we guide students towards achieving their academic dreams abroad, offering unmatched support and resources.
          </Typography>
        </Box>
      </Stack>
    </Container>
  );
};
