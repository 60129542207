import React from "react";
import {
  Box,
  Container,
  Divider,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import { HeadingBlueComponent } from "../../../components/atoms/Headings/HeadingBlueComponent";
import { HeadingRedComponent } from "../../../components/atoms/Headings/HeadingRedComponent";
import { HeadingTagComponent } from "../../../components/atoms/Tags/HeadingTagComponent";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import _ from "lodash";
import { LazyLoadImageComponent } from "../../../components/atoms/LazyLoad/LazyLoadImageComponent";
import { TELEPHONE_ICON } from "../../../assets/Images";
import PhoneIcon from "@mui/icons-material/Phone";
import EmailIcon from "@mui/icons-material/Email";
import LocationOnIcon from "@mui/icons-material/LocationOn";

export const GetInTouchSection = ({}) => {
  const branches = [
    {
      contact_no1: "+94 (77) 225 7575 ",
      contact_no2: "+94 (77) 225 7575 ",
      address: "No: 101, 2nd Floor, Colombo Road, Kurunegala, Sri Lanka. 60000",
      email: "info@oasesedu.com",
    },
  ];
  return (
    <Container maxWidth={"lg"}>
      <Stack spacing={2}>
        <Stack spacing={2} sx={{ alignItems: "center" }}>
          <HeadingTagComponent colorMode="lite" text={"Contact Us"} />
          <Grid
            container
            sx={{ alignItems: "baseline", gap: 1, justifyContent: "center" }}
          >
            <Grid item>
              <HeadingBlueComponent
                variant="h4"
                colorMode="lite"
                text="Get In"
              />
            </Grid>
            <Grid item>
              <HeadingRedComponent variant="h4" text="Touch" />
            </Grid>
          </Grid>
        </Stack>

        <Typography variant="subtitle1" sx={{ textAlign: "center " }}>
          We’re here to assist you. Whether you have questions, need guidance,
          or want to explore opportunities, feel free to contact us. Our team is
          ready to support you in your journey.
        </Typography>

        <Grid
          container
          sx={{
            border: "1px solid #B7D1E1",
            borderRadius: 1,
            // padding: "24px",
            maxWidth: "1200px",
            // margin: "0 auto",
          }}
        >
          {/* Phone Section */}
          <Grid
            item
            xs={12}
            md={4}
            sx={{
             p:2,
              borderRightStyle: "solid",
              borderWidth: 1,
              borderColor: "#B7D1E1",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Box display="flex" textAlign="left" columnGap={2}>
              <PhoneIcon fontSize="medium" color="primary" />
              <Box>
                <Typography
                  variant="subtitle1"
                  sx={{
                    fontWeight: 500,
                    // fontFamily: "OakSans",
                    color: "#01203D",
                  }}
                >
                  Call us Anytime
                </Typography>
                <Typography
                  variant="subtitle2"
                  sx={{ fontWeight: 400, color: "#01203DD9" }}
                >
                  +94 (77) 225 7575
                </Typography>
                <Typography
                  variant="subtitle2"
                  sx={{ fontWeight: 400, color: "#01203DD9" }}
                >
                  +94 (77) 225 7575
                </Typography>
              </Box>
            </Box>
          </Grid>

          {/* Email Section */}
          <Grid
            item
            xs={12}
            md={4}
            sx={{
              borderRightStyle: "solid",
              borderWidth: 1,
              borderColor: "#B7D1E1",
              display: "flex",
              justifyContent: "center",
              p:2
            }}
          >
            <Box display="flex" textAlign="left" columnGap={2}>
              <EmailIcon fontSize="medium" color="primary" />
              <Box>
                <Typography
                  variant="subtitle1"
                  sx={{ fontWeight: 500, color: "#01203D" }}
                >
                  Send us E-mail
                </Typography>
                <Typography
                  variant="subtitle2"
                  sx={{ fontWeight: 400, color: "#01203DD9" }}
                >
                  info@oasesedu.com
                </Typography>
              </Box>
            </Box>
          </Grid>

          {/* Address Section */}
          <Grid item xs={12} md={4} sx={{ p:2}}>
            <Box display="flex" textAlign="left" columnGap={2}>
              <LocationOnIcon fontSize="medium" color="primary" />
              <Box>
                <Typography
                  variant="subtitle1"
                  sx={{ fontWeight: 500, color: "#01203D" }}
                >
                  Visit Our Office
                </Typography>
                <Typography
                  variant="subtitle2"
                  sx={{ fontWeight: 400, color: "#01203DD9" }}
                >
                  No: 101, 2nd Floor, Colombo Road,Kurunegala, Sri Lanka. 60000
                </Typography>
              </Box>
            </Box>
          </Grid>
        </Grid>

        {/* <Swiper
          modules={[Autoplay]}
          autoplay={{
            delay: 2000,
            disableOnInteraction: false,
          }}
        
          breakpoints={{
            640: {
              slidesPerView: 1,
             
            },
            768: {
              slidesPerView: 1,
             
            },
            1024: {
              slidesPerView: 1,
             
            },
            1440: {
              slidesPerView: 1,
             
            },
          }}
          className="custom-swiper"
        >
          {_.map([...branches, ...branches], (branch, index) => {
            return (
              <SwiperSlide key={index + 1}>
             
                <Grid
                  container
                  sx={{
                    border: "1px solid #B7D1E1",
                    borderRadius: 1,
                    // padding: "24px",
                    maxWidth: "1200px",
                    // margin: "0 auto",
                  }}
                >
                 
                  <Grid
                    item
                    xs={12}
                    md={4}
                    sx={{
                      p: 3,
                      borderRightStyle: "solid",
                      borderWidth: 1,
                      borderColor: "#B7D1E1",
                    }}
                  >
                    <Box display="flex" textAlign="left" columnGap={2}>
                      <PhoneIcon fontSize="medium" color="primary" />
                      <Box>
                        <Typography
                          variant="subtitle1 "
                          sx={{ fontWeight: 500, fontFamily:'OakSans', color:'#01203D'}}
                        >
                          Call us Anytime
                        </Typography>
                        <Typography variant="subtitle2" sx={{fontWeight:400, color:'#01203DD9'}}>
                          +94 (77) 225 7575
                        </Typography>
                        <Typography variant="subtitle2" sx={{fontWeight:400,color:'#01203DD9'}}>
                          +94 (77) 225 7575
                        </Typography>
                      </Box>
                    </Box>
                  </Grid>

                
                  <Grid
                    item
                    xs={12}
                    md={4}
                    sx={{
                      p: 3,
                      borderRightStyle: "solid",
                      borderWidth: 1,
                      borderColor: "#B7D1E1",
                    }}
                  >
                    <Box display="flex" textAlign="left" columnGap={2}>
                      <EmailIcon fontSize="medium" color="primary" />
                      <Box>
                        <Typography
                          variant="subtitle1"
                          sx={{ fontWeight: 500,color:'#01203D' }}
                        >
                          Send us E-mail
                        </Typography>
                        <Typography  variant="subtitle2" sx={{fontWeight:400, color:'#01203DD9'}}>info@oasesedu.com</Typography>
                      </Box>
                    </Box>
                  </Grid>

              
                  <Grid item xs={12} md={4} sx={{ p: 3 }}>
                    <Box display="flex" textAlign="left" columnGap={2}>
                      <LocationOnIcon fontSize="medium" color="primary" />
                      <Box>
                        <Typography
                          variant="subtitle1"
                          sx={{ fontWeight: 500 ,color:'#01203D'}}
                        >
                          Visit Our Office
                        </Typography>
                        <Typography  variant="subtitle2" sx={{fontWeight:400, color:'#01203DD9'}}>
                          No: 101, 2nd Floor, Colombo Road,Kurunegala, Sri
                          Lanka. 60000
                        </Typography>
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
              </SwiperSlide>
            );
          })}
          <div className="custom-pagination" />
        </Swiper> */}
      </Stack>
    </Container>
  );
};
