import React, { useState } from "react";
import {
  Box,
  Card,
  CardMedia,
  Container,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import _ from "lodash";
import "swiper/css";
import "swiper/css/navigation";
import { Navigation } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import { HeadingBlueComponent } from "../../../atoms/Headings/HeadingBlueComponent";
import { HeadingRedComponent } from "../../../atoms/Headings/HeadingRedComponent";
import { ArrowIcon } from "../../../atoms/SvgIcons/ArrowIcon";
import { HeadingTagComponent } from "../../../atoms/Tags/HeadingTagComponent";
import "./StudyDestinationStyle.scss";
import {
  LOCATION_AUS,
  LOCATION_CAN,
  LOCATION_ENG,
  LOCATION_GRM,
  LOCATION_IRE,
  LOCATION_JAP,
  LOCATION_LAT,
  LOCATION_SING,
} from "../../../../assets/Images";
import { LazyLoadImageComponent } from "../../../atoms/LazyLoad/LazyLoadImageComponent";

export const StudyDestinations = () => {
  const [hoverFront, setHoverFront] = useState(false);
  const [HoverBack, setHoverBack] = useState(false);
  const studyLocations = [
    { link: "#", image: LOCATION_AUS, title: "Australia" },
    { link: "#", image: LOCATION_CAN, title: "Canada" },
    { link: "#", image: LOCATION_ENG, title: "England" },
    { link: "#", image: LOCATION_SING, title: "Singapore" },
    { link: "#", image: LOCATION_LAT, title: "Latvia" },
    { link: "#", image: LOCATION_JAP, title: "Japan" },
    { link: "#", image: LOCATION_GRM, title: "Germany" },
    { link: "#", image: LOCATION_IRE, title: "Ireland" },
  ];

  return (
    <Container maxWidth={"lg"}>
      <Stack rowGap={3}>
        <Container maxWidth={"sm"}>
          <Stack spacing={1} sx={{ alignItems: "center" }}>
            <HeadingTagComponent colorMode="lite" text="Study Destinations" />
            <Grid
              container
              sx={{
                justifyContent: "center",
                alignItems: "baseline",
                columnGap: 1,
              }}
            >
              <Grid item>
                <HeadingBlueComponent
                  colorMode="lite"
                  variant="h4"
                  text="Our Study Abroad"
                />
              </Grid>
              <Grid item>
                <HeadingRedComponent variant="h4" text="Destinations" />
              </Grid>
            </Grid>

            <Typography variant="subtitle1" sx={{ textAlign: "center" }}>
              Learn about our offices in various countries dedicated to
              providing exceptional educational consultation services.
            </Typography>
          </Stack>
        </Container>

        <Grid container sx={{gap:2}}>
        {_.map(studyLocations, (location, index) => {
          return (
          <Grid item key={index + 1} xl={2.8} lg={2.8} md={2.8} sx={{ position: "relative" }}>
            <Card sx={{ borderRadius: 0, width: "100%", height: 320, borderRadius:3}}>
              <Box >
                <LazyLoadImageComponent
                  src={_.get(location, "image", "")}
                  alt={`destination-img${index + 1}`}
                  styles={{
                    objectFit: "cover",
                    height: 320,
                    width: "100%",
                    borderRadius:3
                  }}
                />
              </Box>
              <Box
                sx={{
                  position: "absolute",
                  bottom: 0,
                  width: "100%",
                  overflow: "hidden",
                  height: "65%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "flex-end",
                  transition: ".6s ease",
                  borderRadius:3,
                  background: {
                    xl: "none",
                    lg: "none",
                    sm: "linear-gradient(to top, #00000095, #ffffff00)",
                    xs: "linear-gradient(to top, #00000095, #ffffff00)",
                  },
                  pb: 2,
                  ":hover": {
                    background: "linear-gradient(to top, #000000, #ffffff00)",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    height: "100%",
                    pb: 0,
                  },
                }}
              >
                <Typography
                  variant="h5"
                  sx={{
                    color: "#ffffff",
                    textAlign: "center",
                    fontWeight: 500,
                  }}
                >
                  {_.get(location, "title")}
                </Typography>
              </Box>
            </Card>
          </Grid> );
        })}
        </Grid>
      </Stack>
     
    </Container>
  );
};
