import React, { useState } from "react";
import {
  Box,
  Container,
  createTheme,
  Grid,
  Stack,
  TextField,
  ThemeProvider,
  useTheme,
} from "@mui/material";
import { HeadingTagComponent } from "../../../../components/atoms/Tags/HeadingTagComponent";
import { HeadingBlueComponent } from "../../../../components/atoms/Headings/HeadingBlueComponent";
import { HeadingRedComponent } from "../../../../components/atoms/Headings/HeadingRedComponent";
import ArrowOutwardIcon from "@mui/icons-material/ArrowOutward";
import { TextButtonComponent } from "../../../../components/atoms/Buttons/TextButtonComponent";
import { CONTACT_US } from "../../../../assets/Images";
import { LazyLoadImageComponent } from "../../../../components/atoms/LazyLoad/LazyLoadImageComponent";
import Swal from "sweetalert2";
import {
  getText,
  PHONE_NO,
  TEXT_ONLY,
  VALIDATE_EMAIL,
} from "../../../../core/utils/Constants";
import ContactUs from "../../../../core/services/ContactUs";
import _ from "lodash";

const customTheme = (outerTheme) =>
  createTheme({
    palette: {
      mode: outerTheme.palette.mode,
    },
    components: {
      MuiTextField: {
        styleOverrides: {
          root: {
            "--TextField-brandBorderColor": "#E0E3E7",
            "--TextField-brandBorderHoverColor": "#B2BAC2",
            "--TextField-brandBorderFocusedColor": "#6F7E8C",
            "& label.Mui-focused": {
              color: "var(--TextField-brandBorderFocusedColor)",
            },
          },
        },
      },

      MuiInput: {
        styleOverrides: {
          root: {
            "&::before": {
              borderBottom: "2px solid var(--TextField-brandBorderColor)",
            },
            "&:hover:not(.Mui-disabled, .Mui-error):before": {
              borderBottom: "2px solid var(--TextField-brandBorderHoverColor)",
            },
            "&.Mui-focused:after": {
              borderBottom:
                "2px solid var(--TextField-brandBorderFocusedColor)",
            },
          },
        },
      },
    },
  });
export const ContactUsSection = ({
  imagePath = CONTACT_US,
  tagName = "Contact us",
}) => {
  const outerTheme = useTheme();
  const [firstName, setFirstName] = useState("");
  const [isValidFirstName, setIsValidFirstName] = useState(true);
  const [errorFirstName, setErrorFirstName] = useState("");

  const [lastName, setLastName] = useState("");
  const [isValidLastName, setIsValidLastName] = useState(true);
  const [errorLastName, setErrorLastName] = useState("");

  const [email, setEmail] = useState("");
  const [isValidEmail, setIsValidEmail] = useState(true);
  const [errorEmail, setErrorEmail] = useState("");

  const [phoneNo, setPhoneNo] = useState("");
  const [isValidPhoneNo, setIsValidPhoneNo] = useState(true);
  const [errorPhoneNo, setErrorPhoneNo] = useState("");

  const [message, setMessage] = useState("");
  const [isMessageValid, setMessageValid] = useState(true);
  const [errorMessage, setErrorMessage] = useState("");

  const onChangeFirstName = (e) => {
    const text = getText(e);
    const isValid = TEXT_ONLY.test(text);
    setFirstName(text);
    setErrorFirstName(!isValid ? "Please enter text only!" : "");
    setIsValidFirstName(isValid);
  };

  const onChangeLastName = (e) => {
    const text = getText(e);
    const isValid = TEXT_ONLY.test(text);
    setLastName(text);
    setErrorLastName(!isValid ? "Please enter text only!" : "");
    setIsValidLastName(isValid);
  };

  const onChangeEmail = (e) => {
    const text = getText(e);
    const isValid = VALIDATE_EMAIL.test(text);
    setEmail(text);
    setErrorEmail(!isValid ? "Please enter a valid Email" : "");
    setIsValidEmail(isValid);
  };

  const onChangeMessage = (e) => {
    const text = getText(e);
    const isValid = TEXT_ONLY.test(text);
    setMessage(text);
    setErrorMessage(!isValid ? "Please enter a valid message!" : "");
    setMessageValid(isValid);
  };

  const onChangPhoneNo = (e) => {
    const text = getText(e);
    const isValid = PHONE_NO.test(text);
    setPhoneNo(text);
    setErrorPhoneNo(!isValid ? "Please enter a valid Phone No" : "");
    setIsValidPhoneNo(isValid);
  };

  const handleOnPressSend = async () => {
    if (
      isValidFirstName &&
      isValidLastName &&
      isValidEmail &&
      isMessageValid &&
      isValidPhoneNo &&
      firstName.length > 0 &&
      lastName.length > 0 &&
      email.length > 0 &&
      message.length > 0
    ) {
      const response = await ContactUs.createMessage({
        first_name: firstName,
        last_name: lastName,
        phone_no: phoneNo,
        email: email,
        message: message,
      });

      if (_.get(response, "status") == 200) {
        setEmail("");
        setFirstName("");
        setLastName("");
        setMessage("");
        setPhoneNo("");
        setErrorPhoneNo("");
        setErrorMessage("");
        setErrorEmail("");
        setErrorFirstName("");
        setIsValidEmail(true);
        setIsValidPhoneNo(true);
        setIsValidFirstName(true);
        setIsValidLastName(true);
        setMessageValid(true);

        Swal.fire({
          title: "Success",
          text: "Message Sent. Thank you for valueble feedback.",
          icon: "success",
          customClass: {
            popup: "custom-swal-font",
            confirmButton: "custom-btn",
          },
        });
      } else {
        Swal.fire({
          title: "Error",
          text: _.get(response, "message"),
          icon: "error",

          customClass: {
            popup: "custom-swal-font",
            confirmButton: "error-custom-btn",
          },
        });
      }
    } else {
      Swal.fire({
        title: "Error",
        text: "Please ensure all fields are filled out correctly before submitting",
        icon: "error",

        customClass: {
          popup: "custom-swal-font",
          confirmButton: "error-custom-btn",
        },
      });
    }
  };

  return (
    <Container maxWidth={"lg"}>
      <Grid container spacing={5}>
        <Grid item xl={6} lg={6} md={6} sm={6} xs={12}  >
          <Stack spacing={3}>
            <Stack spacing={2}>
              <HeadingTagComponent colorMode="lite" text={tagName} />
              <Box>
                <Stack spacing={1}>
                  <HeadingBlueComponent
                    variant="h4"
                    text="We Are Waiting For"
                  />
                  <HeadingRedComponent variant="h4" text="Your Message" />
                </Stack>
              </Box>
            </Stack>

            <Box>
              <ThemeProvider theme={customTheme(outerTheme)}>
                <Stack >
                  <Grid container spacing={2.5}>
                    <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                      <TextField
                        fullWidth
                        placeholder="First Name*"
                        variant="standard"
                        error={!isValidFirstName}
                        value={firstName}
                        helperText={errorLastName}
                        onChange={(e) => onChangeFirstName(e)}
                      />
                    </Grid>
                    <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                      <TextField
                        fullWidth
                        placeholder="Last name*"
                        variant="standard"
                        error={!isValidLastName}
                        value={lastName}
                        helperText={errorLastName}
                        onChange={(e) => onChangeLastName(e)}
                      />
                    </Grid>
                    <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                      <TextField
                        fullWidth
                        placeholder="Email address*"
                        variant="standard"
                        value={email}
                        error={!isValidEmail}
                        helperText={errorEmail}
                        onChange={(e) => onChangeEmail(e)}
                      />
                    </Grid>
                    <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                      <TextField
                        fullWidth
                        placeholder="Phone Number*"
                        variant="standard"
                        error={!isValidPhoneNo}
                        value={phoneNo}
                        helperText={errorPhoneNo}
                        onChange={(e) => onChangPhoneNo(e)}
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        placeholder="Type your message*"
                        variant="standard"
                        multiline
                        rows={4}
                        value={message}
                        error={!isMessageValid}
                        helperText={errorMessage}
                        onChange={(e) => onChangeMessage(e)}
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <Box
                        sx={{
                          width: { xl: "40%", lg: "50%", md: "40%", sm: "50%" },
                        }}
                      >
                        <TextButtonComponent
                          text="Send Message"
                          variant="contained"
                          endIcon={<ArrowOutwardIcon />}
                          sx={{
                            textTransform: "initial",
                            borderRadius: 10,
                            borderColor: "#3D3D3D",
                            backgroundColor: "#052343",
                            color: "#ffffff",
                            fontFamily: "OakSans",
                          }}
                          onClick={handleOnPressSend}
                        />
                      </Box>
                    </Grid>
                  </Grid>
                </Stack>
              </ThemeProvider>
            </Box>
          </Stack>
        </Grid>
        <Grid
          item
          xl={6}
          lg={6}
          md={6}
          sm={6}
          sx={{
            display: {
              xl: "block",
              lg: "block",
              md: "block",
              sm: "block",
              xs: "none",
            },
          }}
        >
          <LazyLoadImageComponent
            src={imagePath}
            alt="contact-us-img"
            styles={{ width: "100%", height: "100%", objectFit: "cover" }}
          />
        </Grid>
      </Grid>
    </Container>
  );
};
