import React, { useEffect, useRef, useState } from "react";
import _ from "lodash";
import { Box, Container, Typography } from "@mui/material";
import { LazyLoadImageComponent } from "../../LazyLoad/LazyLoadImageComponent";
import ReactPlayer from "react-player";
import { VIDEO } from "../../../../assets/Video";

export const HeroSliderComponent = ({ imageList = [], HeroText = "" }) => {
  const videoRef = useRef(null);
  const [poster, setPoster] = useState(null);
  // const videoUrl = ""; // Replace with your video path

  useEffect(() => {
    // Function to capture the first frame
    const captureFirstFrame = () => {
      const video = videoRef.current;
      if (video) {
        const canvas = document.createElement("canvas");
        canvas.width = video.videoWidth;
        canvas.height = video.videoHeight;
        const context = canvas.getContext("2d");

        // Draw the first frame of the video onto the canvas
        context.drawImage(video, 0, 0, canvas.width, canvas.height);

        // Get the data URL of the image
        const dataUrl = canvas.toDataURL("image/jpeg");
        setPoster(dataUrl); // Set the poster to the captured frame
      }
    };

    // Load the hidden video to capture the first frame
    const videoElement = videoRef.current;
    if (videoElement) {
      videoElement.addEventListener("loadeddata", captureFirstFrame);
    }

    return () => {
      if (videoElement) {
        videoElement.removeEventListener("loadeddata", captureFirstFrame);
      }
    };
  }, []);
  return (
    <Container maxWidth={"xl"} style={{ paddingRight: 0, paddingLeft: 0 }}>
      <div style={{ position: 'relative', width: '100%', height: 'auto' }}>
            {/** Hidden video element to capture first frame */}
            <video
                ref={videoRef}
                src={VIDEO}
                style={{ display: 'none' }}
            />

            {/** Display the main video with ReactPlayer */}
            <ReactPlayer
                url={VIDEO}
                playing
                loop
                muted
                width="100%"
                height="100%"
                config={{
                    file: {
                        attributes: {
                            poster: poster // Set the poster to the captured first frame
                        }
                    }
                }}
            />
        </div>
    </Container>
  );
};
